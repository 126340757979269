module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Blue Ocean Insurance","short_name":"Blue Ocean Insurance","start_url":"/","background_color":"#ffffff","theme_color":"#0064BA","display":"standalone","icon":"src/assets/logos/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"816de2ccb04d3ab83f1bfa83fc09492c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-page-transitions/gatsby-browser.js'),
      options: {"plugins":[],"transitionTime":500},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Lato","Work Sans:500,700"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#00A8E3","showSpinner":false,"trickle":false,"height":7},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
