import React, { useState, useRef } from 'react';
import { TimelineLite, Power4 } from 'gsap';
import UseHover from '../../utils/useHover';

import {
    ContainerWrapper,
    TagButton,
    Text,
} from './elements';

import User from './user';

import SVG from './1.svg';
import LoadingSVG from './loading.png';

const TagButtonFC = ({ error = false, cb, }) => {
    const [ clicked, setClicked ] = useState(false);
    const containerRef = useRef();

    const handleMouseOver = () => {
        if (clicked) return true;
        const tl = new TimelineLite({ paused: true });
        tl
            .to(containerRef.current, 1, {
                backgroundColor: '#0064BA',
                ease: Power4.easeInOut,
            })
            .to(containerRef.current, 0.5, {
                width: '110%',
                ease: Power4.easeInOut,
            }, '-=1');
        tl.play();
    }
    const handleMouseLeave = () => {
        if (clicked) return true;
        const originalWidth = typeof window !== 'undefined' && window.innerWidth < 1200 ? 81 : 118;
        const tl = new TimelineLite({ paused: true });
        tl
            .to(containerRef.current, 0.5, {
                width: originalWidth,
                ease: Power4.easeInOut,
            })
            .to(containerRef.current, 1, {
                backgroundColor: '#6FB7FF',
                ease: Power4.easeInOut,
            }, '-=0.5');
        tl.play();
    }
    const handleClick = () => {
        cb();
        setClicked(true);
        const originalWidth = typeof window !== 'undefined' && window.innerWidth < 1200 ? 81 : 118;
        const tl = new TimelineLite({ paused: true });
        tl
            .to(containerRef.current, 0.5, {
                width: originalWidth,
                ease: Power4.easeInOut,
            })
            .to(containerRef.current, 1, {
                backgroundColor: '#6FB7FF',
                ease: Power4.easeInOut,
                delay: 3,
            });
        tl.play();
        setTimeout(() => setClicked(false), 3000);
    }

    return (
        <TagButton
            onMouseEnter={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
        >
            <ContainerWrapper error={error} ref={containerRef} className="circle">
                {
                    clicked && !error ? 
                        <User animation icon={LoadingSVG} /> :
                        <User icon={SVG} />
                }
                <Text className="text" white>
                    <strong>{clicked && !error ? 'Sending...' : 'Send'}</strong>
                </Text>
            </ContainerWrapper>
            <Text className="text">
                <strong>{clicked && !error ? 'Sending...' : 'Send'}</strong>
            </Text>
        </TagButton>
    );
};
export default TagButtonFC;
