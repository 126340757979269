import React from 'react';
import styled from 'styled-components';
import OkAsset from '../../assets/images/ok.svg';
import P from '../common/p';

const Row = styled.div`
    display: flex;
`;

const Col = styled.div`
    width: 50%;
    width: ${props => props.large && '70%'};
    width: ${props => props.small && '30%'};
    text-align: ${props => props.small && 'right'};
`;

const ImageOk = styled.img`
    width: 196px;
    height: auto;
    margin-top: 100px;
`;
const H3 = styled.h3`
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 80px;
    line-height: 80px;
    color: #0064BA;
`;
const OkForm = () => (
    <Row>
        <Col large>
            <H3>Thanks for your enquiry.</H3>
            <P>
                Your request has been successfully forwarded. We will contact you in <strong>few business hours</strong>.
            </P>
        </Col>
        <Col small>
            <ImageOk alt="OK" src={OkAsset} />
        </Col>
    </Row>
)

export default OkForm;