import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { gsap } from 'gsap';

const TextWrapper = styled.div`
    border: none;
    width: auto;
    max-width: 100%;

    height: 72px;
    margin-top: 40px;
    background: #F8F8F8;
    border-radius: 57px;
    padding-left: 30px;
    margin-left: 98px;
    color: #B3B3B3;
    position: relative;
    display: inline-table;
    padding: 10px 20px;

    @media (max-width: 600px) {
        margin-left: 0;
        display: block;
    }
`;
const Placeholder = styled.span`
    position: absolute;
    top: 19px;
    left: 30px;
    color: #B3B3B3;
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 31px;
    pointer-events: none;
    @media (max-width: 1200px) {
        font-size: 22px;
        line-height: 26px;
    }

    @media (max-width: 600px) {
        font-size: 18px;
        line-height: 17px;
        top: 27px;
    }
`;

const Indicator = styled.svg`
    width: 23px;
    height: 19px;
    fill: none;

    position: absolute;
    top: 27px;
    right: 30px;

    path {
        fill: #c4c4c4;
    }
`;
const colourStyles = {
    control: (styles) => ({
        ...styles,
        border: 'none',
        outline: 'none',
        boxShadow: 'none',
        background: 'none',
        width: 200,
    }),
    indicatorsContainer: styles => ({
        ...styles,
        visibility: 'hidden',
    }),
    indicatorSeparator: styles => ({
        ...styles,
        visibility: 'hidden',
    }),

}

const options = [
    { value: 'Mr.', label: 'Mr.', },
    { value: 'Ms.', label: 'Ms.', },
    { value: 'Mrs.', label: 'Mrs.', },
    { value: 'Dr.', label: 'Dr.', },
    { value: 'Oknha', label: 'Oknha', },
];

const SelectComponent = ({
    placeholder = 'Title',
    onChange = false,
}) => {
    const placeholderRef = useRef();
    const arrowRef = useRef();
    const [ open, setOpen ] = useState(false);
    const [ value, setValue ] = useState(null);

    useEffect(() => {
        let fontSize = [26, 18];
        if (typeof window !== 'undefined' && window.innerWidth < 1200) {
            fontSize = [22, 16];
        }
        if (typeof window !== 'undefined' && window.innerWidth < 600) {
            fontSize = [18, 14];
        }
        gsap.to(arrowRef.current, 0.3, {
            rotate: open ? -180 : 0,
        })
        if (open) {
            gsap.to(placeholderRef.current, 0.3, {
                y: -50,
                x: -5,
                fontSize: fontSize[1],
                color: '#000'
            });
        } else if (!value || value === '') {
            gsap.to(placeholderRef.current, 0.3, {
                y: 0,
                x: 0,
                fontSize: fontSize[0],
                color: '#B3B3B3'
            })
            
        }
    }, [ open ])
    return <TextWrapper>
        <Placeholder ref={placeholderRef}>{placeholder}</Placeholder>
        <Indicator ref={arrowRef} width="23" height="19" viewBox="0 0 23 19" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.5 19L0.674682 0.25L22.3253 0.250002L11.5 19Z" fill="#C4C4C4" />
        </Indicator>
        <Select
            menuIsOpen={open}
            onMenuOpen={() => setOpen(true)}
            onMenuClose={() => setOpen(false)}
            className="title-select"
            classNamePrefix="select"
            options={options}
            placeholder=""
            value={value}
            onChange={(v) => {
                setValue(v);
                if ( onChange ) {
                    onChange(v.value);
                }
            }}
            styles={colourStyles}
        />
    </TextWrapper>
}

export default SelectComponent;
