import styled from 'styled-components';

export const ContainerWrapperParent = styled.div`
    position: fixed;
    bottom: 100px;
    right: calc(50vw - 675px);
    width: 180px;
    height: 180px;
    @media (max-width: 1600px) {
        right: calc(50vw - 545px);
    }
    @media (max-width: 1200px) {
        right: 57px;
        z-index: 999;
    }
    
    @media (max-width: 600px) {
        display: none;
    }
    &:hover {
        cursor: pointer;
    }
`;
export const ContainerWrapper = styled.div`
    width: 160px;
    height: 160px;
    border-radius: 50%;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    z-index: 500;
    transition: all 0.65s cubic-bezier(0,.68,.6,2.01);
    

    svg {
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 0;
        transform: translate(-50%, -50%) rotate(270deg);
        transition: all 0.45s cubic-bezier(0.5,0,0,1);

        width: 190px;
        height: 190px;
        @media (max-width: 1200px) {
            width: 145px;
            height: 145px;
        }
        circle {
            transition: all 0.45s cubic-bezier(0.5,0,0,1);
        }
    }
    
    @media (max-width: 1200px) {
        width: 113px;
        height: 113px;
        bottom: 53px;
        right: 57px;
    }

    &:hover {
        cursor: pointer;
        width: 180px;
        height: 180px;
        @media (max-width: 1200px) {
            width: 135px;
            height: 135px;
        }
    }
`;

export const BorderWhite = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #FFFFFF;
    overflow: hidden;
    z-index: 0;
`;

export const Img = styled.img`
    position: absolute;
    width: 50px;
    height: 50px;
    z-index: 999;
    top: 6px;
    right: 15px;
    @media (max-width: 1200px) {
        top: 30px;
        right: 25px;
        width: 40px;
        height: 40px;
    }
`;

export const CenteredText = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 150px;
    height: auto;
    text-align: center;
    z-index: 999;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 22px;
    color: #0064BA;

    width: 150px;
    height: 66px;

    pointer-events: none;
    @media (max-width: 1200px) {
        width: 100px;
        height: 48px;
        max-width: 100px;
        font-size: 14px;
        line-height: 16px;
    }
    div {
        position: absolute;
        top: 0;
        left: 0;
        width: 150px;
        height: 66px;

        @media (max-width: 1200px) {
            width: 100px;
            height: 48px;
        }
    }
    strong {
        font-weight: 700;
    }
`;

export default ContainerWrapper;
