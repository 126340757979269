import styled from 'styled-components';

export const OverlayBlue = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    transition: all 0.4s ease;
    display: none;
    @media (max-width: 1200px) {
        display: block;
        background: rgba(58, 119, 175, 0.7);
    }
    @media (max-width: 600px) {
        display: none;
    }
`;

export const MainContainerOverlayMobile = styled.div`
    position: fixed;
    width: 537px;
    height: 100vh;
    right: 0;
    top: 0px;
    z-index: 9999;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);

    @media (max-width: 600px) {
        width: 100%;
    }
`;

export const MainContainerOverlayDesktop = styled.div`
    position: fixed;
    width: 100vw;
    max-width: 1410px;
    overflow: hidden;
    height: 114px;
    top: 40px;
    right: calc(50vw - 700px);
    z-index: 9999;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
    display: flex;

    @media (max-width: 1600px) {
        right: calc(50vw - 575px);
        max-width: 1140px;
    }
    
    @media (max-width: 1200px) {
        right: 57px;
    }

    @media (max-width: 600px) {
        width: 100%;
    }
`;

export const MainOverlayCentered = styled.div`
    position: absolute;
    
    left: 50%;
    transform: translate(-50%,-50%);
    top: 45%;
    @media (min-width: 1200px) {
        top: 50%;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 140px;
    }
`;

export const Title = styled.h2`
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    color: ${props => props.disabled && '#ABABAB'};
    pointer-events: ${props => props.disabled && 'none'};
    margin: 0 20px;
    opacity: 0;

    @media (max-width: 1200px) {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 34px;
        margin-left: 0;
        margin-bottom: ${props => props.last && '106px'};
    }

    @media (min-width: 600px) and (max-width: 1200px) {
        white-space: nowrap;
    }

    &:hover {
        cursor: pointer;
        color: #0064BA;
    }
`;

export const CloseToggle = styled.div`
    width: 34px;
    height: 34px;
    background-color: #0064BA;
    position: absolute;
    top: 40px;
    right: 70px;
    border-radius: 50%;
    transition: background-color 0.4s cubic-bezier(0.5,0,0,1);

    @media (max-width: 1200px) {
        display: none;
    }
    
    &:hover {
        cursor: pointer;
        background-color: #00A8E3;
    }

    span {
        width: 19px;
        height: 3px;
        background-color: #FFF;
        position: absolute;

        &:nth-child(1) {
            top: 15px;
            left: 8px;
            transform: rotate(45deg);
        }

        &:nth-child(2) {
            top: 15px;
            left: 8px;
            transform: rotate(-45deg);
        }
        
    }
`;

export const LogoDesktopWrapper = styled.div`
    display: block;
    top: 18px;
    left: 60px;
    position: absolute;
    @media (max-width: 1200px) {
        display: none;
    }
`;

export const CloseToggleMobile = styled.div`
    width: 34px;
    height: 34px;
    background-color: #0064BA;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    transition: background-color 0.4s ease;
    &:hover {
        cursor: pointer;
        background-color: #00A8E3;
    }
    @media (min-width: 1200px) {
        display: none;
    }
    @media (max-width: 600px) {
        right: -50px;
        top: -4px;
    }
    span {
        width: 19px;
        height: 3px;
        background-color: #FFF;
        position: absolute;

        &:nth-child(1) {
            top: 15px;
            left: 8px;
            transform: rotate(45deg);
        }
        &:nth-child(2) {
            top: 15px;
            left: 8px;
            transform: rotate(-45deg);
        }
        
    }
`;

export const Credits = styled.h4`
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 135%;
    /* or 16px */
    color: #0064BA;
    @media (min-width: 1200px) {
        display: none;
    }
`;

export const MenuWrapperText = styled.h4`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 34px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.1em;

    color: #FFFFFF;

    width: 56px;
    height: 34px;

    background: #0064BA;
    border-radius: 23px;
    user-select: none;

    transition: all 0.2s ease-in-out;
    position: fixed;
    top: 77px;
    
    z-index: 9999;
    right: calc(50vw - 675px);
    @media (max-width: 1600px) {
        right: calc(50vw - 525px);
    }
    @media (max-width: 1200px) {
        right: 57px;
    }
    @media (max-width: 600px) {
        right: 15px;
    }
    &:hover {
        cursor: pointer;
        background-color: #00A8E3;
    }
`;

export const MenuLanguageText = styled.h4`
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    color: #000000;

    &:hover {
        cursor: pointer;
    }
`;

export const MenuLanguageContainer = styled.div`
    position: fixed;
    width: 60px;
    height: 15px;
    top: 86px;
    
    z-index: 10;
    
    white-space: nowrap;
    right: calc(50vw - 590px);

    @media (max-width: 1600px) {
        right: calc(50vw - 440px);
    }
    @media (max-width: 1200px) {
        right: 135px;
    }
    @media (max-width: 600px) {
        right: 90px;
    }
`;

export const MenuLanguagePopup = styled.div`
    position: absolute;
    top: 110%;
    left: 0;
    width: 60px;
    height: auto;
    background-color: #FFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
    h3 {
        font-family: 'Lato';
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        text-transform: uppercase;
        margin: 10px 0;
        color: #000000;
        &:hover {
            cursor: pointer;
        }
    }
`;


export const MenuClaimsText = styled.h4`
    position: fixed;
    width: auto;
    height: 15px;
    top: 86px;
    z-index: 10;
    white-space: nowrap;
    right: calc(50vw - 540px);

    @media (max-width: 1600px) {
        right: calc(50vw - 400px);
    }
    
    @media (max-width: 1200px) {
        right: 181px;
    }
    
    @media (max-width: 600px) {
        right: 128px;
    }

    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-align: right;

    color: #000000;
`;

export default MainContainerOverlayMobile;