const React = require('react');
const Layout = require('./src/global/Layout').default;

require("nprogress/nprogress.css");
require('./nprogress.css');

exports.wrapPageElement = ({ element, props }) => {
    return (<Layout {...props}>
        {element}
    </Layout>);
}

exports.onRouteUpdate = () => {
    const EVENT_NAME = 'end-transition';
    if (typeof window !== 'undefined') {
        const event = new Event(EVENT_NAME);
        window.dispatchEvent(event);
    }
}
