import React from 'react';

export const formStatus = {
    contact: {
        active: false,
        subTitle: '',
        title: ''
    },
    specific: {
        active: false,
        subTitle: '',
        title: ''
    }
};

const FormContext = React.createContext(formStatus);


export const FormContextProvider = FormContext.Provider;
export const FormContextConsumer = FormContext.Consumer;


export default FormContext;