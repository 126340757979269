import React, { useState, useEffect, useRef, useContext } from 'react';
import { gsap } from 'gsap';
import UseHover from '../../utils/useHover';

import {
    ContainerWrapperParent,
    ContainerWrapper,
    CenteredText,
    BorderWhite
} from './elements.js';
import FormContext from '../../global/formContext';

import User from './user';

const RequestButton = ({
    normalText = 'Request Consultation',
    boldText = 'Now',
}) => {
    const formStatus = useContext(FormContext);
    const setForm = formStatus.setFormContact;
    let interval;
    let sizes = 180;
    if (typeof window !== 'undefined' && window.innerWidth < 1200) {
        sizes = 135;
    }
    const defaultOffset = ((sizes[0] + sizes[1]) * 2);
    const [outputLength, setOutputLength] = useState(-defaultOffset);
    const [clicked, setClicked] = useState(false);
    const [hoveredInternal, setHoveredInternal] = useState(false);
    const [ref, isHover] = UseHover();
    
    const text1Ref = useRef();

    const handleResize = () => {
        setOutputLength(2*defaultOffset);
    }

    const handleClick = () => {
        // console.log('handle click', clicked);
        if (clicked) {
            return false;
        }

        setClicked(true);

        
        setTimeout(() => {
            setClicked(false);
            setForm({
                active: true,
                title: 'Contact Us',
                subTitle: 'Request Consultation',
            });

        }, 500)
    };

    const handleHover = () => {
        setOutputLength(0);
    }

    useEffect(() => {
        setHoveredInternal(isHover);
    }, [isHover])

    useEffect(() => {
        const resizeBind = handleResize.bind(this);
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', resizeBind);
        };
        interval = setInterval(() => {
            if (!isHover) {
                setHoveredInternal(true);
                setTimeout(() => setHoveredInternal(false), 650);
            }
        }, 8000)
        return () => window.removeEventListener('resize', resizeBind);
    }, []);


    return (
        <ContainerWrapperParent
            onClick={handleClick}>
            <ContainerWrapper
                ref={ref}
                style={hoveredInternal ? {
                    width: sizes,
                    height: sizes,
                } : {}}
            >
                
                <BorderWhite>
                    
                </BorderWhite>
            </ContainerWrapper>
            <User />
            <CenteredText >
                <div ref={text1Ref}>
                    <span dangerouslySetInnerHTML={{
                        __html: normalText
                    }} />
                    <br />
                    <strong dangerouslySetInnerHTML={{
                        __html: boldText
                    }}
                    />
                </div>
            </CenteredText>
        </ContainerWrapperParent>
    );
};

export default RequestButton;
