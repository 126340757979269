import React from 'react';
import { navigate } from 'gatsby';

const EVENT_NAME = 'start-transition';

const CustomLink = ({ to, children, timeout = 0.75, disabled = false}) => {
    const handleClick = (masterEvent) => {
        masterEvent.preventDefault();
        
        const event = new Event(EVENT_NAME);
        window.dispatchEvent(event);
        if (typeof window !== 'undefined' && window.scrollLib) {
            window.scrollLib.scrollTo(0);
        }
        if (!disabled) {
            setTimeout(() => {
                navigate(to);
            }, timeout * 1000);
        }
    }

    return <a
        href={to}
        onClick={(e) => handleClick(e)}
    >
        {children}
    </a>
}

export default CustomLink;
