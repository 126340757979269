// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-en-js": () => import("./../src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-kh-js": () => import("./../src/pages/about.kh.js" /* webpackChunkName: "component---src-pages-about-kh-js" */),
  "component---src-pages-commercial-en-js": () => import("./../src/pages/commercial.en.js" /* webpackChunkName: "component---src-pages-commercial-en-js" */),
  "component---src-pages-commercial-js": () => import("./../src/pages/commercial.js" /* webpackChunkName: "component---src-pages-commercial-js" */),
  "component---src-pages-commercial-kh-js": () => import("./../src/pages/commercial.kh.js" /* webpackChunkName: "component---src-pages-commercial-kh-js" */),
  "component---src-pages-contact-en-js": () => import("./../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-kh-js": () => import("./../src/pages/contact.kh.js" /* webpackChunkName: "component---src-pages-contact-kh-js" */),
  "component---src-pages-index-en-js": () => import("./../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-kh-js": () => import("./../src/pages/index.kh.js" /* webpackChunkName: "component---src-pages-index-kh-js" */),
  "component---src-pages-news-js": () => import("./../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-personal-en-js": () => import("./../src/pages/personal.en.js" /* webpackChunkName: "component---src-pages-personal-en-js" */),
  "component---src-pages-personal-js": () => import("./../src/pages/personal.js" /* webpackChunkName: "component---src-pages-personal-js" */),
  "component---src-pages-personal-kh-js": () => import("./../src/pages/personal.kh.js" /* webpackChunkName: "component---src-pages-personal-kh-js" */),
  "component---src-templates-product-index-js": () => import("./../src/templates/product/index.js" /* webpackChunkName: "component---src-templates-product-index-js" */)
}

