import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { MenuLanguageText, MenuLanguageContainer, MenuLanguagePopup } from './elements';
const LANGUAGES = [
    {
        lang: 'en',
        active: true,
    }, {
        lang: 'kh',
        active: true,
    }, {
        lang: 'zh',
        active: false, // set true to change language
    }
];


const MenuLanguage = ({ langKey = 'en', pathname }) => {
    const [ open, setOpen ] = useState(false);

    const handleClick = (newLang) => {
        window.location.href = (pathname.replace('/' + langKey + '/', '/' + newLang + '/'))
    }
    useEffect(() => {
        if ( typeof window !== 'undefined' ) {
            window.addEventListener('click', () => {
                setOpen(false);
            })
        }
    }, [])
    return (
        <MenuLanguageContainer>
            <MenuLanguageText
                onClick={(evt) => {
                    evt.stopPropagation();
                    evt.preventDefault();
                    setOpen(true);
                }}
            >
                {langKey.toUpperCase()}
            </MenuLanguageText>
            {
                open ? <MenuLanguagePopup>
                    {
                        LANGUAGES.map((e) => e.active ? (
                            <h3
                                onClick={
                                    () => handleClick(e.lang)
                                }
                            >
                                {e.lang}
                            </h3>
                        ) : <noscript /> )
                    }
                </MenuLanguagePopup> : <noscript />
            }
        </MenuLanguageContainer>
    )   
}

export default MenuLanguage;