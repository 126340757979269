import * as React from 'react';
import styled from 'styled-components';

import TopLogo from './TopLogo.png';

const LOGO_WIDTH = 151;
const LOGO_HEIGHT = 77;
const Img = styled.img`
  width: ${LOGO_WIDTH}px;
  max-width: 100%;
  height: ${LOGO_HEIGHT}px;
  width: ${props => props.small && '81px'};
  height: ${props => props.small && '41px'};
  margin-top: ${props => props.small && '18px'};
`;
const Logo = ({small = false}) => (
  <Img
    src={TopLogo}
    alt=""
    small={small}
    width={LOGO_WIDTH}
    height={LOGO_HEIGHT}
  />
);

export default Logo;
