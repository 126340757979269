import React, { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { StaticQuery, graphql } from 'gatsby';
import { withScroll } from '../requestConsultationButton/withScroll';
import {
    MenuWrapperContainer,
    MenuWrapperText,
    MenuClaimsText
} from './elements';
import MenuLanguage from './menulanguage';

import Overlay from './overlay';


const Menu = ({
    scrollPosition,
    langKey = 'en',
    location: {
        pathname,
    },
    data
}) => {
    const claimsRef = useRef();

    const [lastScroll, setLastScroll] = useState(0);
    const [moveY, setMoveY] = useState(0);
    const [claimsVisible, setClaimsVisible] = useState(true);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if ( scrollPosition < 100 && !claimsVisible) {
            setClaimsVisible(true);
        }
        if ( scrollPosition > 100 && claimsVisible) {
            setClaimsVisible(false);
        }
    }, [scrollPosition]);

    useEffect(() => {
        if ( claimsVisible ) {
            gsap.to(claimsRef.current, 0.2, {
                y: 0,
                opacity: 1,
            });
        } else {
            gsap.to(claimsRef.current, 0.2, {
                y: -50,
                opacity: 0,
            });
        }
    }, [claimsVisible]);

    const handleToggle = () => {
        if ( open ) { setOpen(false); }
        else { setOpen(true); }
    }

    return (
        <>
            <MenuClaimsText ref={claimsRef}>
                <a href={"tel:" + data.markdownRemark.frontmatter.phone.trim()}>
                    {
                        langKey === 'en' ? 'Call Now' : 'ទូរស័ព្ទមកយើង'
                    }
                </a>
            </MenuClaimsText>
            <MenuLanguage
                langKey={langKey}
                pathname={pathname}
            />
            <MenuWrapperText data-attr="ignore-mouse" onClick={() => handleToggle()}>MENU</MenuWrapperText>
            <Overlay
                open={open}
                langKey={langKey}
                toggleOpen={handleToggle}
            />
        </>
    );
}

export function myMenu(props) {
    return (
        <StaticQuery
            query={graphql`
                query {
                    markdownRemark(frontmatter: {templateKey: {eq: "contact-page"}}) {
                        frontmatter {
                            phone
                        }
                    }
                }
            `}
            render={data => <Menu data={data} {...props} />}
        />
    )
};


export default withScroll(myMenu);