import React from 'react';
import { Img } from './elements';

const User = ({ icon, animation }) => (
    <Img
        src={icon}
        animation={animation}
        alt="User"
    />
);

export default User;