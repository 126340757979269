import React, { useState, useEffect } from 'react';


const { Provider, Consumer } = React.createContext();


const ScrollProvider = (props) => {
    if (typeof window === 'undefined') {
        // return nothing in case of 
        return <noscript />;
    }
    const [ scrollPosition, setScrollPosition ] = useState(0);

    useEffect(() => {
        let intervalScroll = null;
        intervalScroll = setInterval(() => {
            if (window.scrollLib) {
                clearInterval(intervalScroll);
                window.scrollLib.on('scroll', (event) => {
                    setScrollPosition(event.scroll.y);
                });
            }
        })
        setTimeout(() => {
           
        }, 2000);
    }, []);

    return (
        <Provider value={{
            scrollPosition,
        }}>
            {props.children}
        </Provider>
    )
};


// higher order
export const withScroll = C => props => (
    <Consumer>
        {value => <C {...value} {...props} /> }
    </Consumer>
);


export default ScrollProvider;