import React, { useRef, useEffect, useState } from 'react';
import { gsap } from 'gsap';
import styled from 'styled-components';

const TextWrapper = styled.div`
    border: none;
    width: 600px;
    max-width: 100%;

    width: ${props => props.little && '404px'};
    width: ${props => props.full && '100%'};
    height: 72px;
    margin-top: 40px;
    background: #F8F8F8;
    border-radius: 57px;
    padding-left: 30px;
    color: #B3B3B3;
    position: relative;
`;

const Requirement = styled.span`
    position: absolute;
    top: 25px;
    left: -20px;

    font-size: 26px;
    line-height: 31px;

    color: #B3B3B3;
    color: ${props => props.error && '#f40000'};
`;

const Placeholder = styled.span`
    position: absolute;
    top: 20px;
    left: 30px;
    color: #B3B3B3;
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 31px;
    pointer-events: none;
    @media (max-width: 1200px) {
        font-size: 22px;
        line-height: 26px;
    }

    @media (max-width: 600px) {
        font-size: 14px;
        line-height: 17px;
        top: 27px;
    }
`;

const Input = styled.input`
    border: none;
    outline: none;
    color: #333;
    outline: none;
    border: none;
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 31px;
    width: 100%;
    height: 100%;
    position: relative;
    background: none;
    @media (max-width: 1200px) {
        font-size: 22px;
        line-height: 26px;
    }

    @media (max-width: 600px) {
        font-size: 18px;
        line-height: 17px;
    }
`;
const Text = ({ placeholder, little, full, onChange = false, required = false, error = false }) => {
    const placeholderRef = useRef();

    const [ currentText, setCurrentText ] = useState('');
    const [ focused, setFocused ] = useState(false);

    useEffect(() => {
        let fontSize = [ 26, 18 ];
        if ( typeof window !== 'undefined' && window.innerWidth < 1200 ) {
            fontSize = [ 22, 16 ];
        }
        if ( typeof window !== 'undefined' && window.innerWidth < 600 ) {
            fontSize = [ 18, 14 ];
        }
        if ( focused ) {
            gsap.to(placeholderRef.current, 0.3, {
                y: -50,
                x: -5 ,
                fontSize: fontSize[1] ,
                color: '#000'
            })
        } else if ( currentText === '' ) {
            gsap.to(placeholderRef.current, 0.3, {
                y: 0,
                x: 0,
                fontSize: fontSize[0],
                color: '#B3B3B3'
            })
        }
    }, [ focused ]);

    return <TextWrapper little={little} full={full}>
        {required ? <Requirement error={error}>*</Requirement> : <noscript />}
        <Placeholder ref={placeholderRef}>{placeholder}</Placeholder>
        <Input
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            value={currentText}
            onChange={(event) => {
                const txt = event.target.value;
                setCurrentText(txt);
                if ( onChange ) {
                    onChange(txt);
                }
            }}
        />
    </TextWrapper>
}

export default Text;
