import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import WindowExists from '../utils/windowExists';
import ScrollProvider from '../components/requestConsultationButton/withScroll.js';

import RequestConsultationButton from '../components/requestConsultationButton';
import Menu from '../components/menu';
import Form from '../components/form';
import FormPersonal from '../components/formPersonal';

import Loader from '../components/loader';
// import Noise from '../components/common/noise';
import Logo from '../components/common/toplogo';
import GlobalStyle from './GlobalStyle';
import { FormContextProvider, formStatus } from './formContext';

import Transition from '../components/common/appear/transition';

const Layout = ({
  children,
  location,
  pageContext,
  path
}) => {
  const [formContactStatus, setFormContact] = useState({ active: false, subTitle: '', title: '' })
  const [formPersonalStatus, setFormPersonal] = useState({ active: false, subTitle: '', title: '' })
  const [ loaded, setLoaded ] = useState(false);
  const [ faded, setFaded ] = useState(false);

  let timer = null;
  useEffect(() => {
    if (!WindowExists()) {
      return true;
    }

    async function initScroll() {
      const LocomotiveScroll = (await import('locomotive-scroll')).default;

      let scroll = new LocomotiveScroll({
        el: document.getElementById('scroll-container'),
        smooth: true,
        scrollFromAnywhere: true,
      });
      window.scrollLib = scroll;
    }
    function fadeOut() {
      // start-transition
      setFaded(true);
    }

    function fadeIn() {
      setFaded(false);
    }

    async function startup() {
      await initScroll();
      timer = setInterval(() => {
        window.scrollLib.update();
      }, 1000);
      window.addEventListener('ui-form-toggle', () => {
        setFormVisible(!formVisible);
      });
      window.addEventListener('start-transition', () => fadeOut());
      window.addEventListener('end-transition', () => setTimeout(() => fadeIn(), 1000));
    }

    startup();

    return () => {
      if ( timer ) clearInterval(timer);
    }
  }, []);

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={() => (
        <React.StrictMode>
          <ScrollProvider>
            <>
              <Helmet>
                <title>Blue Ocean Insurance Broker</title>
                <meta
                  name="apple-mobile-web-app-status-bar-style"
                  content="default"
                />
              </Helmet>
              <Loader
                active={!loaded}
                endCallback={() => setLoaded(true)}
              />
              <FormContextProvider value={{
                  contact: {
                    active: formContactStatus.active,
                    subTitle: formContactStatus.subTitle,
                    title: formContactStatus.title
                  },
                  specific: {
                    active: formPersonalStatus.active,
                    subTitle: formPersonalStatus.subTitle,
                    title: formPersonalStatus.title
                  },
                  setFormContact,
                  setFormPersonal
              }}>
                <Logo
                  langKey={pageContext && pageContext.langKey}
                />
                <Menu
                  path={path}
                  location={location}
                  langKey={pageContext && pageContext.langKey}
                />
                <Form
                  langKey={pageContext && pageContext.langKey}
                />
                <FormPersonal
                  langKey={pageContext && pageContext.langKey}
                  title={formPersonalStatus.title}
                  subTitle={formPersonalStatus.subTitle}
                />
                <GlobalStyle />
                <div
                  id="scroll-container"
                  data-scroll-container
                >
                  <Transition location={location}>
                    {children}
                  </Transition>
                </div>
                <RequestConsultationButton
                  langKey={pageContext && pageContext.langKey}
                  toggleForm={() => setFormVisible(!formVisible)}
                  normalText={
                    pageContext && pageContext.langKey === 'kh' ? "ទំនាក់ទំនង<br/>ពួកយើង" : "Request<br/>Consultation" }
                  boldText={pageContext && pageContext.langKey === 'kh' ? "ឥឡូវ​នេះ" : "Now" }
                  
                />
              </FormContextProvider>
            </>
          </ScrollProvider>
        </React.StrictMode>
      )}
    />
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
