import React, { useEffect, useRef, useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Link from '../common/customlink';
import { gsap, TweenMax, } from 'gsap';

import menuVoices from './examplevoices.js';
import Logo from '../common/logo';
import WindowExists from '../../utils/windowExists';
import { debounce } from '../../utils/debounce';
import {
    OverlayBlue,
    MainContainerOverlayMobile,
    MainContainerOverlayDesktop,
    MainOverlayCentered,
    Title,
    Credits,
    CloseToggle,
    CloseToggleMobile,
    LogoDesktopWrapper,
} from './elements';

const Overlay = ({
    data,
    open,
    toggleOpen,
    langKey = 'en'
}) => {
    // states
    const [innerOpen, setInnerOpen ] = useState(open);
    const [key, setKey] = useState(0);
    const [wasClickedOutside, setWasClickedOutside] = useState(false);

    // Refs
    const refContainer = useRef();
    const refLogo = useRef();
    const refToggleMobile = useRef();
    const refToggleDesktop = useRef();
    let titleRef = useRef([]);

    // General
    let ContainerComponent = null;

    function animateTitles(opening) {
        if (opening) {
            TweenMax.staggerTo(titleRef.current, 0.45, {
                opacity: 1,
                x: 0,
            }, 0.05);
        } else {
            TweenMax.staggerTo(titleRef.current, 0.45, {
                opacity: 0,
                x: 35,
            }, 0.05);
        }
    }

    function animateOpen() {
        if (!WindowExists()) return false;
        TweenMax.to(refLogo.current, 0.4, {
            delay: 0.45,
            x: 0,
            opacity: 1
        });
        TweenMax.to([refToggleDesktop.current, refToggleMobile.current], 0.4, {
            delay: 0.85,
            opacity: 1
        });


        if (window.innerWidth > 1200) {
            gsap.to(
                refContainer.current,
                0.3,
                {
                    width: '100%',
                    onComplete: () => animateTitles(true),
                }
            );
        } else {
            gsap.to(
                refContainer.current,
                0.3,
                {
                    x: 0,
                    width: '100%',
                    opacity: 1,
                    onComplete: () => animateTitles(true),
                }
            );
        }
    }

    function animateClose() {
        TweenMax.to(refLogo.current, 0.4, {
            delay: 0.45,
            x: 30,
            opacity: 0
        });
        TweenMax.to([refToggleDesktop.current, refToggleMobile.current], 0.4, {
            delay: 0.45,
            opacity: 0
        });


        if (window.innerWidth > 1200) {
            animateTitles(false);
            gsap.to(
                refContainer.current,
                0.4,
                {
                    width: '0%',
                    delay: 0.7,
                }
            );
        } else {
            gsap.to(
                refContainer.current,
                0.4,
                {
                    x: '100%',
                    opacity: 0,
                    delay: 0.7,
                }
            );
        }
    }

    useEffect(() => {
        if ( open ) {
            animateOpen();

            if (wasClickedOutside) {
                setWasClickedOutside(false);
            }
        } else {
            animateClose();
        }
    }, [open]);


    useEffect(() => {
        if ( open && wasClickedOutside ) {
            toggleOpen();
            setWasClickedOutside(false);
        }
    }, [ wasClickedOutside ])

    useEffect(() => {
        if (WindowExists()) {
            window.addEventListener('resize', () => {
                debounce(() => setKey(Math.random()), 100);
            });
            window.addEventListener('click', (event) => {
                if (event.target.dataset.attr !== 'ignore-mouse') {
                    setWasClickedOutside(true);
                }
            }, false);
        }
    }, [] );

    if ( WindowExists() && window.innerWidth < 1200 ) {
        ContainerComponent = MainContainerOverlayMobile;
    } else {
        ContainerComponent = MainContainerOverlayDesktop;
    }

    return (
        <>
            <OverlayBlue
                key={key}
                style={{
                    opacity: open ? 1 : 0,
                    pointerEvents: open ? 'visible' : 'none',
                }}
                onClick={WindowExists() && window.innerWidth < 1200 ? toggleOpen : () => {}}
            />
            <ContainerComponent
                ref={refContainer}
                style={{
                    pointerEvents: open ? 'visible' : 'none',
                    width: 0,
                }}
            >
                <MainOverlayCentered data-attr="ignore-mouse">
                    <LogoDesktopWrapper ref={refLogo}>
                        <Link to="/"><Logo small /></Link>
                    </LogoDesktopWrapper>
                    <CloseToggle data-attr="ignore-mouse" onClick={toggleOpen} ref={refToggleDesktop}>
                        <span data-attr="ignore-mouse" />
                        <span data-attr="ignore-mouse" />
                    </CloseToggle>
                    <CloseToggleMobile data-attr="ignore-mouse" onClick={toggleOpen} ref={refToggleMobile}>
                        <span data-attr="ignore-mouse" />
                        <span data-attr="ignore-mouse" />
                    </CloseToggleMobile>
                    {
                        data.allMarkdownRemark && data.allMarkdownRemark.edges.sort((a,b) => a.node.frontmatter.order - b.node.frontmatter.order).map((element, index) => element.node.frontmatter.active ? (
                            <Link
                                to={`/${langKey}${element.node.frontmatter.link}`}
                                key={'link-' + index}
                                exit={{ length: 1 }}
                                entry={{ delay: 0.6 }}
                            >
                                <Title
                                    last={index === data.allMarkdownRemark.edges.length - 1}
                                    ref={element => {titleRef.current[index] = element}}
                                >
                                    {element.node.frontmatter.text}
                                </Title>
                            </Link>
                        ) : <Title
                                last={index === data.allMarkdownRemark.edges.length - 1}
                                disabled={true}
                                key={'link-' + index}
                                ref={element => { titleRef.current[index] = element }}
                            >
                                {element.node.frontmatter.text}
                            </Title>
                        )
                    }
                    <Credits>
                        © 2020 Blue Ocean Group. All Rights Reserved.
                    </Credits>
                </MainOverlayCentered>
            </ContainerComponent>
        </>
    );
}

export default function myOverlay(props) {
    return (
        <StaticQuery
            query={graphql`
                query {
                    allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "menu-items"}}}) {
                        edges {
                            node {
                                frontmatter {
                                    text
                                    link
                                    active
                                    order
                                }
                            }
                        }
                    }
                }
            `}
            render={data => <Overlay {...props} data={data} />}
        />
    )
}