import React, { useState, useEffect, useRef } from 'react'
import { gsap, Power2, Linear } from 'gsap';
import { Background, BarToExpand } from './elements';
import WindowExists from '../../utils/windowExists';
import Bg from '../../assets/images/example2.jpg';

const Loader = ({ active, endCallback }) => {
  const obj = {
    progress: 0,
  };
  const destination = WindowExists() ? window.innerWidth : 0;
  
  const refParent = useRef();
  const refBar = useRef();

  const [progress, setProgress] = useState(0);
  const [visible, setVisible] = useState(true);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    gsap.to(obj, 2, {
      progress: destination / 2,
      delay: 1,
      ease: Linear.easeNone,
      onUpdate: () => {
        setProgress(obj.progress);
      },
    });
  }, []);

  useEffect(() => {
    async function propagateEndEvent() {
      if (WindowExists()) {
        const event = new Event('end-loading');
        window.dispatchEvent(event);
      }
    }
    function removeLoader() {
      gsap.to(
        refBar.current,
        0.45, {
        opacity: 0,
        onComplete: () => {
          gsap.to(
            refParent.current,
            0.75,
            {
              delay: 0.45,
              opacity: 0,
              ease: Power2.easeInOut,
              onComplete: () => {
                setVisible(false);
                endCallback();
                propagateEndEvent();
              }
            }
          )
        }
      }
      );
    }

    function endLoading() {
      gsap.to(obj, 1, {
        progress: destination,
        ease: Power2.easeOut,
        onUpdate: () => {
          setProgress(obj.progress);
        },
        onComplete: () => {
          setProgress(destination);
          removeLoader();
        },
      });
    }

    if ( ready ) {
      endLoading();
    }
  }, [ ready ]);
  /*
  if (!visible) {
    return <div />;
  }
  */
  // console.log(active, ready, progress);
  return (
    <Background
      ref={refParent}
      active={active}
    >
      <BarToExpand
        style={{
          width: `${progress}px`,
        }}
        ref={refBar}
      />
      <img
        src={Bg} alt="" style={{ visibility: 'hidden', width: 1, height: 1, position: 'fixed', top: -100, left: -100 }} onLoad={() => setReady(true)} />
    </Background>
  );
};

export default Loader;
