import styled from 'styled-components';

/* Main wrapper */

export const MainContainer = styled.div`
    opacity: 0;
    pointer-events: visible;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 99999;
`;

/*
    This is general overlay for form
    Light blue overlay, bind close on
    it.
*/

export const Overlay = styled.div`
    position: fixed;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    background: rgba(58, 119, 175, 0.7);
    overflow: auto;
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const FillText = styled.p`
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    color: #B3B3B3;
    color: ${props => props.error && '#F40000'};
    width: 100%;
    margin-top: 10px;
`;
/*
    Form container
*/

export const Wrapper = styled.div`
    overflow-x: hidden;
    overflow-y: scroll;
    max-width: 1200px;
    width: 96%;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.35);
    position: relative;
    
    left: 50%;
    transform: translateX(-50%);
    height: auto; 
    padding: 101px;
    margin-bottom: 110px;
    overflow: hidden;

    top: 110px;
    @media (max-width: 1200px) {
        padding: 101px 62px;
        top: 70px;
        margin-bottom: 70px;
    }
    @media (max-width: 600px) {
        top: 14px;
        margin-bottom: 80px;
        padding: 101px 22px;
    }
    &::-webkit-scrollbar {
        display: none;
    }

`;

/*
    Heading 4
*/
export const H4 = styled.h4`
    font-family: 'Work Sans';
    font-weight: normal;
    font-size: 30px;
    line-height: 35px;
    color: #00A8E3;

    @media (max-width: 1200px) {
        font-size: 30px;
        line-height: 35px;
    }
    
    @media (max-width: 600px) {
        font-size: 20px;
        line-height: 23px;
    }
`;

/* Heading 2 */
export const H2 = styled.h2`
    font-family: 'Work Sans';
    font-weight: bold;
    font-size: 92px;
    line-height: 80px;
    /* or 87% */
    margin-bottom: 20px;
    color: #0064BA;

    @media (max-width: 1200px) {
        font-size: 70px;
        line-height: 70px;
        /* or 100% */
    }

    @media (max-width: 600px) {
        font-size: 44px;
        line-height: 52px;
    }
`;

export const Row = styled.div`
    display: flex;
    flex-wrap: nowrap;
    @media (max-width: 600px) {
        display: block;
    }
`;

export const CloseToggle = styled.div`
    width: 34px;
    height: 34px;
    background-color: #C4C4C4;
    position: absolute;
    top: 60px;
    right: 60px;
    border-radius: 50%;
    transition: background-color 0.4s cubic-bezier(0.5,0,0,1);
    
    &:hover {
        cursor: pointer;
        background-color: #00A8E3;
    }

    @media (max-width: 600px) {
        top: 35px;
        right: 25px;
    }
    span {
        width: 19px;
        height: 3px;
        background-color: #FFF;
        position: absolute;

        &:nth-child(1) {
            top: 15px;
            left: 8px;
            transform: rotate(45deg);
        }

        &:nth-child(2) {
            top: 15px;
            left: 8px;
            transform: rotate(-45deg);
        }
        
    }
`;
export const FormWrapper = styled.div`
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 80px;
`;
export default {
    MainContainer,
    Overlay,
    Wrapper,
    H2,
    H4,
    FormWrapper,
    CloseToggle
}
