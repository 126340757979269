import React, { useState, useEffect } from 'react'
import { gsap } from 'gsap';

import styled from 'styled-components';
import { withScroll } from '../../requestConsultationButton/withScroll';
import CustomLink from '../customlink';


import TopLogo from './TopLogo.png';

const LOGO_WIDTH = 151;
const LOGO_HEIGHT= 77;


const LOGO_WIDTH_MOBILE = 104;

const Img = styled.img`
  width: ${LOGO_WIDTH}px;
  max-width: 100%;
  height: ${LOGO_HEIGHT}px;
  position: absolute;
  top: 66px;
  left: calc(50vw - 675px);
  @media (max-width: 1600px) {
      left: calc(50vw - 525px);
  }
  @media (max-width: 1200px) {
      left: 57px;
  }
  z-index: 10;

  @media (max-width: 600px) {
    width: ${LOGO_WIDTH_MOBILE}px;
    height: auto;
    left: 15px;
  }

  &:hover {
    cursor: pointer;
  }
`;
const Logo = ({ scrollPosition, }) => {

  const logoRef = React.createRef();

  const [logoVisible, setLogoVisible] = useState(false);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (scrollPosition < 100 && !logoVisible) {
      setLogoVisible(true);
    }
    if (scrollPosition > 100 && logoVisible) {
      setLogoVisible(false);
    }
  }, [scrollPosition]);

  useEffect(() => {
    if (initialized) {
      if (logoVisible) {
        gsap.to(logoRef.current, 0.2, {
          y: 0,
          opacity: 1,
        });
      } else {
        gsap.to(logoRef.current, 0.2, {
          y: -50,
          opacity: 0,
        });
      }
    }
  }, [logoVisible, initialized]);

  useEffect(() => {
    gsap.fromTo(logoRef.current, 0.6, {
      y: 50,
      opacity: 0,
    }, {
      y: 0,
      opacity: 1,
      onComplete: () => setInitialized(true),
    });
  }, [])
  return (
    <CustomLink to="/">
      <Img
        src={TopLogo}
        ref={logoRef}
        alt=""
        width={LOGO_WIDTH}
        height={LOGO_HEIGHT}
        style={{ opacity: 0 }}
        to={'/'}
      />
    </CustomLink>
  );
}

export default withScroll(Logo);
