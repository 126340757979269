import styled from 'styled-components';


export const P = styled.p`
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    margin-top: 34px;
    margin-top: ${props => props.nmt && '0px'};
    color: #0E0E0E;

    @media (max-width: 600px) {
        font-size: 18px;
        line-height: 24px;
    }
`;

export default P;