import React, { useState, useEffect, useContext } from 'react';
import { gsap } from 'gsap';
import SendButton from '../sendButton';
import FormContext from '../../global/formContext';
import {
    MainContainer,
    Overlay,
    Wrapper,
    H4,
    H2,
    FormWrapper,
    CloseToggle,
    Row,
    FillText
} from '../form/elements';
import P from '../common/p';
import Text from '../common/input/text';
import Phone from '../common/input/phone';
import Textarea from '../common/input/textarea';
import Select from '../common/input/select';
import OkForm from '../okform';

const INVISIBLE_STYLE = {
    pointerEvents: 'none',
};
const API_ENDPOINT = '/.netlify/functions/mail';
function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
function validatePhone(phone) {
    const v = phone.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im);
    if (v) {
        return true;
    } else {
        return false;
    }
}
const Form = ({
    subTitle = 'Personal Insurance',
    title = 'Automobile Insurance.',
    disableForm = () => {},
}) => {
    const formStatus = useContext(FormContext);
    const mainRef = React.createRef();
    const setForm = formStatus.setFormPersonal;

    const [error, setError] = useState(false);
    const [mailSent, setMailSent] = useState(false);
    const [ formState, setFormState ] = useState({
        name: '',
        title: '',
        lastname: '',
        phone: '',
        email: '',
        msg: '',
        honeyEmail: '',
    })
    const enableScroll = () => {
        if (typeof window !== 'undefined' && window.scrollLib) {
            window.scrollLib.start();
        }
    }
    const disableScroll = () => {
        if (typeof window !== 'undefined' && window.scrollLib) {
            window.scrollLib.stop();
        }
    }

    const submitForm = async () => {
        try {
            if (error) {
                setError(false);
            }
            const payload = {
                ...formState,
                insurance: title,
                category: subTitle,
            }
            const response = await fetch(API_ENDPOINT, {
                method: "POST",
                body: JSON.stringify(payload),
            });
            /*
            console.log('validate:',
                validateEmail(formState.email),
                formState.name !== '',
                validatePhone(formState.phone),
                formState.lastname !== '',
                response
            )*/
            if (
                validateEmail(formState.email) &&
                formState.name !== '' &&
                validatePhone(formState.phone) &&
                formState.lastname !== '' &&
                response.ok
            ) {
                setMailSent(true);
                console.log('mail sent');
            } else {
                setError(true);
                setTimeout(() => {
                    // console.log('timeout');
                    setError(false);
                }, 3000);
            }
        } catch (e) {
            console.log('error', e);
        }
    }

    useEffect(() => {
        if (!formStatus.specific.active) {
            gsap.to(mainRef.current, 0.4, {
                opacity: 0,
            })
            enableScroll();
        } else {
            gsap.to(mainRef.current, 0.4, {
                opacity: 1,
            });
            disableScroll();
        }
        if (mailSent) {
            setMailSent(false);
        }
        if (error) {
            setError(false);
        }
    }, [formStatus.specific.active]);

    return (
        <MainContainer
            ref={mainRef}
            style={formStatus.specific.active ? {} : INVISIBLE_STYLE }
        >
            <Overlay onClick={
                () => {
                    setForm({
                        active: false,
                        title: formStatus.specific.title,
                        subTitle: formStatus.specific.subTitle,
                    })
                }
            }>
                <Wrapper
                    onClick={
                        (event) => {
                            event.stopPropagation();
                            event.preventDefault();
                        }
                    }
                >
                    <CloseToggle onClick={
                        () => {
                            setForm({
                                active: false,
                                title: formStatus.specific.title,
                                subTitle: formStatus.specific.subTitle,
                            })
                        }
                    }>
                        <span /><span />
                    </CloseToggle>
                    {
                    mailSent ? <OkForm /> :
                    <>
                        <H4>{subTitle}</H4>
                        <H2>{title}</H2>
                        <P> Fill in the following information and you will be contacted within few business hours. </P>
                        <FormWrapper>
                            <Row>
                                <Text
                                    error={error}
                                    required
                                    placeholder="Name"
                                    onChange={(txt) => {
                                        setFormState({
                                            ...formState,
                                            name: txt,
                                        })
                                    }}
                                />
                                <Select
                                    onChange={(txt) => {
                                        setFormState({
                                            ...formState,
                                            title: txt,
                                        })
                                    }}
                                />
                            </Row>
                            <Text
                                error={error}
                                required
                                placeholder="Lastname"
                                onChange={(txt) => {
                                    setFormState({
                                        ...formState,
                                        lastname: txt,
                                    })
                                }}
                            />
                            <Phone
                                error={error}
                                required
                                placeholder="Phone"
                                little
                                onChange={(txt) => {
                                    setFormState({
                                        ...formState,
                                        phone: txt,
                                    })
                                }}
                            />
                            <Text
                                error={error}
                                required
                                placeholder="Email"
                                full
                                onChange={(txt) => {
                                    setFormState({
                                        ...formState,
                                        email: txt,
                                    })
                                }}
                            />
                            <Textarea
                                placeholder="Any specific request or details"
                                onChange={(txt) => {
                                    setFormState({
                                        ...formState,
                                        msg: txt,
                                    })
                                }}
                            />
                            <FillText error={error}>Please fill all mandatory fields before sending your request.</FillText>
                        </FormWrapper>
                        <Row>
                            <SendButton
                                error={error}
                                cb={() => submitForm()}
                            />
                        </Row>
                    </>}
                </Wrapper>
            </Overlay>
        </MainContainer>
    )
}


export default Form;
