import styled, { keyframes } from 'styled-components';


export const ContainerWrapper = styled.div`
    width: 118px;
    height: 118px;
    border-radius: 96px;
    background-color: #6FB7FF;
    background-color: ${props => props.error && '#F40000 !important'};
    transition: ${props => props.error && 'all 0.2s ease-in-out'};
    width: ${props => props.error && '110% !important'};

    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 5;
    @media (max-width: 1200px) {
        width: 81px;
        height: 81px;
    }

`;

export const TagButton = styled.div`
    width: auto;
    height: auto;
    display: flex;
    flex-wrap: nowrap;
    margin: 0px;
    position: relative;
    z-index: 0;
    width: 230px;
    height: 77px;
    &:hover {
        cursor: pointer;
    }
`;

// Create the keyframes
const rotate = keyframes`
  from {
    transform: translateY(-50%) rotate(0deg);
  }

  to {
    transform: translateY(-50%) rotate(360deg);
  }
`;

export const Img = styled.img`
    position: absolute;
    width: 69px;
    height: auto;
    top: 50%;
    left: 24px;
    transform: translateY(-50%);
    animation: ${props => props.animation && rotate} 2s linear infinite;
    @media (max-width: 1200px) {
        left: 19px;
        width: 44px;
    }
`;

export const Text = styled.div`
    position: absolute;
    left: 130px;
    top: 36px;

    max-width: 200px;
    height: auto;
    text-align: left;

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #0064BA;
    color: ${props => props.white && 'white'};
    position: relative;
    z-index: 0;

    @media (max-width: 1200px) {
        top: 25px;
        left: 90px;
    }
    strong {
        font-family: 'Lato';
        font-style: normal;
        font-weight: normal;
        font-size: 48px;
        line-height: 70%;
        margin-left: -3px;
        @media (max-width: 1200px) {
            font-size: 40px;
            line-height: 28px;
            /* or 28px */
        }
    }
`;


export default TagButton;
