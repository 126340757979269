import React from 'react';

import { Img } from './elements';
import SVG from './user.svg';

const User = () => (
    <Img src={SVG} alt="User" />
);

export default User;
