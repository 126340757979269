import styled from 'styled-components';

export const BarToExpand = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    background: #0064ba;
    width: 0;
    height: 7px;
`;

export const Background = styled.div`
    opacity: 0;
    opacity: ${props => props.active && '1'};

    pointer-events: none;
    pointer-events: ${props => props.active && 'visible'};
    background-color: rgba(255,255,255,1);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99999;
`;

export default {
    Background,
    BarToExpand,
}
